import 'regenerator-runtime/runtime';
import React from "react";
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import Table from "./Table";

const queryClient = new QueryClient();

export function App() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
        <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 pt-4">
            <div className="">
                <h1 className="text-xl text-plowgreen font-semibold">Plow Networks Managed Device Viewer</h1>
            </div>
            <div className="mt-4">
              <QueryClientProvider client={queryClient}>
                <Table />
                <ReactQueryDevtools initialIsOpen />
              </QueryClientProvider>
            </div>
        </main>
    </div>
  );
}

// export default App;