export const FetchDevices = async (id, page, pageSize) => {
  const url = "https://cloudradialsharepointbridge.azurewebsites.net/api/PAGEndPointComplianceAPI";
  const code = "U5u3y7IOX7rFsKWKlsrzUANPfHOpIHqMmqCCElEmndrEAzFua11Osw==";
  const offset = page * pageSize;
  try {
    const response = await fetch(
      `${url}?code=${code}&CompanyID=${id}&Offset=${offset}&Limit=${pageSize}`
    );
    const data = await response.json();

    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};