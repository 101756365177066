export const SubmitSupportTicket = async (autoTask, newTicket) => {
  console.log(JSON.stringify(newTicket));
  const url = "https://cloudradialsharepointbridge.azurewebsites.net/api/New-SupportTicket";
  const code = "ZpPq8Zg0B5W8c5vNnzxV14bXCC96mDm4M3KC_ZJsbGRbAzFu7O0Lsg==";
  try {
    const response = await fetch(
      `${url}?code=${code}&CompanyID=${autoTask}`, {
        method: 'POST',
        header: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newTicket),
      }
    );
    const data = await response.json();

    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};