import { useState } from "react";
import { Modal, Tooltip } from "flowbite-react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function Loading() {
    return (
        <h2 className="flex justify-center items-center mx-auto px-4 py-2 font-semibold leading-6 text-lg rounded-md text-plowgreen hover:bg-green-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-plowgreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
        </h2>
    );
}

export function PlowToolTip({ content }) {
    return (
        <Tooltip content={content}><QuestionMarkCircleIcon className="inline ml-2 h-4 w-4 text-color-white" /></Tooltip>
    )
}

export function PatchedModal() {
    const [showPatchedModal, setShowPatchedModal] = useState(false);
    return (
        <>
            <a className="" onClick={e => {e.preventDefault(); setShowPatchedModal(true)}} href="#">
                <PlowToolTip content="Shows a device’s most recent patching status. Click to see status details." />
            </a>
            <Modal
              show={showPatchedModal}
              onClose={() => setShowPatchedModal(false)}
            >
              <Modal.Header>
                Overview of Patched Status Messages
              </Modal.Header>
              <Modal.Body>
                <div className="space-y-6">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">Shows a device&rsquo;s most recent patching status. Those statuses are:</p>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">No Policy:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">The device is not targeted by any Patch Management policy.</dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">No Data:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">No patch audit data is available. For more information, see the process flow and explanation of steps below.</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Reboot Required:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">The device requires reboot.</dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Install Error:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">One or more errors were encountered during the most recent patch installation run.</dd>
                            </div>
                            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Approved Pending:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">The device has approved pending patches that will be applied during the next patch window.</dd>
                            </div>
                            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Fully Patched:</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">The device is fully patched.</dd>
                            </div>
                        </dl>
                    </div>
                </div>
              </Modal.Body>
            </Modal>
        </>
    );
}