import React, { useState } from "react";
import { useMutation, useQuery } from 'react-query';
import { FetchDeviceDetail } from './FetchDeviceDetail';
import { SubmitSupportTicket } from "./SubmitSupportTicket";
import { Modal } from "flowbite-react";
import { SupportButton } from "./shared/Button";
import { isEmpty, Loading } from "./shared/Utils";
import { SupportIcon } from "@heroicons/react/solid";

export function Datto({ detail }) {
    if (detail) {
        return (
            <Modal.Body>
            <div className="space-y-6 h-96 overflow-scroll">
                <div className="bg-white overflow-hidden sm:rounded-lg">
                    <dl>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Serial Number</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.hostname}</dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Device Type</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                Category: {detail.deviceType.category}<br />
                                Type: {detail.deviceType.type}
                            </dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Operating System</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {detail.operatingSystem}
                            </dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Last Logged In User</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {detail.lastLoggedInUser}
                            </dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Warranty Date</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.warrantyDate != 'Unknown' ? new Date(detail.warrantyDate).toLocaleDateString() : 'Unknown'}</dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Patch Management</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                Status: {detail.patchManagement.patchStatus.replace(/([A-Z])/g, ' $1')}<br />
                                Approved/Pending: {detail.patchManagement.patchesApprovedPending}<br />
                                Not Approved: {detail.patchManagement.patchesNotApproved}<br />
                                Installed: {detail.patchManagement.patchesInstalled}

                            </dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Anti-Virus</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.antivirus.antivirusProduct}<br />{detail.antivirus.antivirusStatus.replace(/([A-Z])/g, ' $1')}</dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Checked-In</dt>
                        {/* <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.CheckedIn180.toLowerCase() == 'true' ? <CheckCircleIcon className="inline h-8 w-8 text-plowgreen" /> : <ExclamationCircleIcon className="inline h-8 w-8 text-red-700" />}</dd> */}
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">IP Addresses</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                Internal: {detail.intIpAddress}<br />
                                External: {detail.extIpAddress}
                            </dd>
                        </div>
                        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Relevant Dates</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                Created: {detail.creationDate != '' ? new Date(detail.creationDate).toLocaleDateString() : 'Unknown'}<br />
                                Last Seen: {detail.lastSeen != '' ? new Date(detail.lastSeen).toLocaleDateString() : 'Unknown'}<br />
                                Last Reboot: {detail.lastReboot != '' ? new Date(detail.lastReboot).toLocaleDateString() : 'Unknown'}<br />
                                Last Audit: {detail.lastAuditDate != '' ? new Date(detail.lastAuditDate).toLocaleDateString() : 'Unknown'}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            </Modal.Body>
        )
    }
}

export function Jamf({ detail }) {
    if (detail) {
        return (
            <Modal.Body>
                <div className="space-y-6 h-96 overflow-scroll">
                    <div className="bg-white overflow-hidden sm:rounded-lg">
                        <dl>
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Name</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.general.name}</dd>
                            </div>
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Serial Number</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.general.serial_number}</dd>
                            </div>
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Device Type</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    Make: {detail.hardware.make}<br />
                                    Model: {detail.hardware.model}
                                </dd>
                            </div>
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Operating System</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {detail.hardware.os_name} {detail.hardware.os_version}
                                </dd>
                            </div>
                            {/* <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Warranty Date</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.warrantyDate != 'Unknown' ? new Date(detail.warrantyDate).toLocaleDateString() : 'Unknown'}</dd>
                            </div> */}
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Patch Management</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {isEmpty(detail.software.available_updates) ? 'No Updates Available' : `Available Updates: ${detail.software.available_updates.update.name}`}
                                </dd>
                            </div>
                            {/* <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Anti-Virus</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.antivirus.antivirusProduct}<br />{detail.antivirus.antivirusStatus.replace(/([A-Z])/g, ' $1')}</dd>
                            </div> */}
                            {/* <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">Checked-In</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{detail.CheckedIn180.toLowerCase() == 'true' ? <CheckCircleIcon className="inline h-8 w-8 text-plowgreen" /> : <ExclamationCircleIcon className="inline h-8 w-8 text-red-700" />}</dd>
                            </div> */}
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">IP Addresses</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    Internal: {detail.general.ip_address}
                                </dd>
                            </div>
                            <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">Relevant Dates</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    Created: {detail.general.initial_entry_date != '' ? new Date(detail.general.initial_entry_date_utc).toLocaleDateString() : 'Unknown'}<br />
                                    Last Seen: {detail.general.last_contact_time != '' ? new Date(detail.general.last_contact_time_utc).toLocaleDateString() : 'Unknown'}<br />
                                    Last Enrolled: {detail.general.last_enrolled_date_utc != '' ? new Date(detail.general.last_enrolled_date_utc).toLocaleDateString() : 'Unknown'}<br />
                                    MDM Profile Expiration: {detail.general.mdm_profile_expiration_utc != '' ? new Date(detail.general.mdm_profile_expiration_utc).toLocaleDateString() : 'Unknown'}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </Modal.Body>
        )
    }
}

export const Device = ({ value, autoTask, rowId, system, site }) => {
    const [showModal, setShowModal] = useState(false);
    const [fade, setFade] = useState(false);
    const { isLoading, error, data, refetch, isSuccess } = useQuery(
        ['deviceDetail', autoTask, rowId, system],
        () => FetchDeviceDetail(autoTask, rowId, system),
        {
            enabled: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        }
    );
    const submitTicket = useMutation(newTicket => {
        SubmitSupportTicket(autoTask, newTicket);
    });
    const modalData = async () => {
        setShowModal(true);
        refetch();
    }
    return (
        <div className="text-sm font-medium text-gray-900">
            <a className="underline underline-offset-2 hover:no-underline hover:text-plowgreen" onClick={e => {e.preventDefault(); modalData()}} href="#">{value}</a>
            <Modal
                show={showModal}
                size="5xl"
                onClose={() => setShowModal(false)}
            >
                <Modal.Header>
                    <span className="text-lg leading-6 font-medium text-gray-900">
                        {value}
                    </span>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        {site}
                    </p>
                </Modal.Header>
                {isLoading && <Loading />}
                {error && (
                  <div>{`There is a problem fetching the post data - ${error}`}</div>
                )}
                {data && system === 'Datto' ? <Datto detail={data} /> : <Jamf detail={data} />}
                <Modal.Footer>
                    {submitTicket.isLoading ? (
                        'Adding Ticket...'
                    ) : (
                        <>
                            <SupportButton className="rounded-full" onClick={() => {
                                submitTicket.mutate({
                                    Subject: `Support Ticket for Device ${value}`,
                                    Issue: 'Please contact me to discuss the issues associated with this device.'
                                });
                                setTimeout(() => {
                                    setFade(true);
                                }, 3500);
                            }}>
                                Support
                                <SupportIcon className="inline ml-2 h-4 w-4 text-color-white" />
                            </SupportButton>
                            {submitTicket.isError ? (
                                <h5 className="text-md text-gray-900 font-normal">An error occurred: {mutation.error.message}</h5>
                            ) : null}

                            {submitTicket.isSuccess ? <h5 className={"text-md text-gray-900 font-normal transition-opacity duration-300 " + `${!fade ? "opacity-100" : "opacity-0"}`}>Ticket Submitted!</h5> : null}
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    )
}