// src/Table.js
import React, { useState } from "react";
import { useTable, useGlobalFilter, useAsyncDebounce, useFilters, useSortBy, usePagination } from "react-table";
import { useQuery } from 'react-query';
import { FetchDevices } from './FetchDevices';
import { Device } from "./Device";
import { classNames, Loading, PlowToolTip, PatchedModal } from "./shared/Utils";
import { PageButton, SupportButton } from "./shared/Button";
import { SortIcon, SortDownIcon, SortUpIcon } from "./shared/Icons";
import { Modal, Tooltip } from "flowbite-react";
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon, QuestionMarkCircleIcon, ShieldCheckIcon, ShieldExclamationIcon, CheckCircleIcon, ExclamationCircleIcon, SupportIcon } from "@heroicons/react/solid";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const idVal = urlParams.get('CompanyID') || "184";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">Search: </span>
        <input
        type="text"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50 placeholder:italic"
        value={value || ""}
        onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        />
    </label>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
        <span className="text-gray-700">{render("HeaderString")}: </span>
        <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
            name={id}
            id={id}
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option.replace(/([A-Z])/g, ' $1').trim()}
            </option>
          ))}
        </select>
    </label>
  );
}

export function FileCell({ value, column, row }) {
  const supportEmail = () => {
    window.location = `mailto:knilob@gmail.com?subject=Support Ticket for Device ${value}`;
  }
  const detail = row.original;

  return (
    <div className="flex items-center">
      <Device value={value} autoTask={detail.AutoTaskCompanyID} rowId={detail.ID} system={detail.System} site={detail.SiteName} />
    </div>
  );
}

export function StatusPill({ value, column}) {
  const status = value ? value.toLowerCase() : "unknown";
  const statusDisplay = column.id == 'AntiVirus' ? status == "true" ? <ShieldCheckIcon className="inline h-8 w-8 text-plowgreen" /> : <ShieldExclamationIcon className="inline h-8 w-8 text-red-700" /> : status == "true" ? <CheckCircleIcon className="inline h-8 w-8 text-plowgreen" /> : <ExclamationCircleIcon className="inline h-8 w-8 text-red-700" />;

  return (
    <span
      className={classNames(
        "px-6 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("true") ? "text-plowgreen" : null,
        status.startsWith("false") ? "text-red-700" : null
      )}
    >
      {statusDisplay}
    </span>
  );
}

const columns = [
  {
    Header: "Device",
    accessor: "Hostname",
    Cell: FileCell,
  },
  {
    Header: "Serial",
    accessor: "Serial",
  },
  {
    Header: "Warranty Date",
    accessor: "WarrantyDate",
    Cell: ({ cell: { value } }) => value != 'Unknown' ? <div>{new Date(value).toLocaleDateString()}</div> : "Unknown",
    sortType: (rowA, rowB, columnId) => {
      let [a, b] = [
        rowA.values[columnId] != 'Unknown' ? new Date(rowA.values[columnId]) : null,
        rowB.values[columnId] != 'Unknown' ? new Date(rowB.values[columnId]) : null
      ];

      a = a ? a.getTime() : null;
      b = b ? b.getTime() : null;

      return a === b ? 0 : a > b ? 1 : -1;
    }
  },
  {
    Header: <div className="group flex items-center justify-between">
      Anti-Virus <PlowToolTip content="Shows if a device has an anti-virus program installed and working." />
    </div>,
    accessor: "AntiVirus",
    Cell: StatusPill,
  },
  {
    Header: <div className="group flex items-center justify-between">
      Patched <PatchedModal />
    </div>,
    HeaderString: "Patched",
    accessor: "Patched",
    Cell: ({ cell: { value } }) => <div>{value.replace(/([A-Z])/g, ' $1').trim()}</div>,
    Filter: SelectColumnFilter,
    filter: "includes",
  },
  {
    Header: <div className="group flex items-center justify-between">
      Checked-In <PlowToolTip content="Shows if a device has communicated with the device management system within the past 180 days." />
    </div>,
    accessor: "CheckedIn180",
    Cell: StatusPill,
  },
];

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
};

const PAGE_CHANGED = 'PAGE_CHANGED';
const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

function Table() {

  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);

  const { isLoading, error, data, isSuccess } = useQuery(
    ['devices', idVal, queryPageIndex, queryPageSize],
    () => FetchDevices(idVal, queryPageIndex, queryPageSize),
    {
      keepPreviousData: true,
    }
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable({
    columns,
    data: isSuccess ? data.results : [],
    initialState: {
      pageIndex: queryPageIndex,
      pageSize: queryPageSize,
    },
    manualPagination: true,
    pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    autoResetFilters: false,
    autoResetGlobalFilter: false,
    autoResetSortBy: false,
    autoResetPage: false,
  },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    window.scrollTo({top: 0});
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
    window.scrollTo({top: 0});
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]);

  if (error) {
    return <div>{`There is a problem fetching the post data - ${error}`}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  // Render the UI for your table
  return (
    <>
      {isSuccess ? (
        <>
        <div className="flex gap-x-2">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter} />
          {headerGroups.map((headerGroup) => headerGroup.headers.map((column) => column.Filter ? (
            <div key={column.id}>
              {column.render("Filter")}
            </div>
          ) : null
          )
          )}
        </div><div className="mt-2 flex flex-col">
            <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table {...getTableProps()} className="table-auto min-w-full divide-y divide-gray-200">
                    <thead className="bg-plowgreen">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-tight whitespace-nowrap"
                              {...column.getHeaderProps(column.getSortByToggleProps())}>
                              <div className="group flex items-center justify-between">
                                {column.render('Header')}
                                <span>
                                  {column.canSort
                                    ? column.isSorted
                                      ? column.isSortedDesc
                                        ? <SortDownIcon className="w-4 h-4 text-white" />
                                        : <SortUpIcon className="w-4 h-4 text-white" />
                                      : <SortIcon className="w-4 h-4 text-white opacity-0 group-hover:opacity-100" />
                                   : null
                                  }
                                </span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr className="even:bg-green-50" {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className="px-6 py-4 whitespace-nowrap"
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
          <div className="py-3 flex items-center justify-between">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div className="flex gap-x-2 items-center">
                <span className="text-sm text-gray-700">
                  Page <span className="font-medium">{pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
                </span>
                <label>
                  <span className="sr-only">Items Per Page</span>
                  <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    } }
                  >
                    {[5, 10, 20, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <PageButton
                    className="rounded-l-md"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    <span className="sr-only">First</span>
                    <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton
                    onClick={() => nextPage()}
                    disabled={!canNextPage}>
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                  <PageButton
                    className="rounded-r-md"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    <span className="sr-only">Last</span>
                    <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                  </PageButton>
                </nav>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Table;