export const FetchDeviceDetail = async (autoTask, id, system) => {
  const url = "https://cloudradialsharepointbridge.azurewebsites.net/api/Get-DeviceDetails";
  const code = "dpoppMwVkE_WJOz7ShXeulmbgLrwRLp8XTcZqCuvuRO0AzFum3bQ3w==";
  try {
    const response = await fetch(
      `${url}?code=${code}&autoTaskCompanyID=${autoTask}&id=${id}&system=${system}`
    );
    const data = await response.json();

    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};